import React, { memo } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

const KbzPayLogo = memo((props) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "mpu.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, height: 60)
        }
      }
    }
  `)

  return <GatsbyImage image={data.logo.childImageSharp.gatsbyImageData} alt="MPU" {...props} />
})

export default KbzPayLogo
